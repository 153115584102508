<template>
    <div id="dashboard" class="py-3">
        <b-row>
            <b-col sm="12" lg="4" md="4" v-if="modules.length > 0">
                <b-list-group>
                    <b-list-group-item v-for="(module, index) in modules" :key="index" :href="`/manual/${training.id}/modulo/${module.id}`">
                        <fa style="vertical-align: middle; font-size: 22px" icon="angle-right" /> {{ module.title }}
                    </b-list-group-item>
                </b-list-group>
            </b-col>

            <b-col sm="12" :lg="modules.length > 0 ? 8 : 12" :md="modules.length > 0 ? 8 : 12">
                <b-card class="mb-2">
                    <h1>{{training.title}}</h1>
                    <hr>
                    {{ training.description }}
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    components: {
    },
    computed: {
        modules() {
            var modules = this.training && this.training.modules && this.training.modules.length > 0 ? this.training.modules : [];
            return modules;
        }
    },
    data() {
        return {
            loadingData: false,
            training: [],
        }
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.loadingData = true;
            this.$store.dispatch('training/getTrainingById', this.$route.params.id).then(data => {
                this.loadingData = false;
                this.training = data ? data : [];
            });
        },
    }
}
</script>

<style scoped>
    h1 {
        font-size: 26px;
        color: #823D9A;
    }

    h4 {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 3px solid #AAA;
        padding-bottom: 4px;
    }
</style>