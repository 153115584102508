<template>
    <div>
        <b-row>
            <b-col sm="12" md="9" lg="9" class="form-group bg-white py-2">
                <h4 class="p-0 mt-1 text-mary-help-purple">Manuais</h4>
            </b-col>
            <b-col sm="12" md="3" lg="3" class="form-group bg-white py-2">
                <router-link class="btn btn-mary-help btn-block" to="/admin/manuais/novo"><b-icon icon="clipboard-plus" class="mr-2" />Novo Manual</router-link>
            </b-col>
        </b-row>
        <b-card>
            <b-row>
                <b-col sm="12" md="12" lg="12">
                    <rich-table v-on:input="ChangeTableConfig" :numbers="table_numbers"
                        :loading="loadingData" :cols="table_cols">
                        <template v-if="items && items.length > 0">
                            <tr v-for="(item, key) in items" v-bind:key="key"
                                :class="item.page + (item.selected ? ' selected' : '')">
                                <td>{{item.title}}</td>
                                <td width="5%" class="text-center">
                                    <b-button variant="primary" size="sm" @click="$router.push({ path: 'manuais/' + item.id })" title="Editar Manual">
                                        <b-icon icon="pen" font-scale="1" />
                                    </b-button>
                                </td>
                                <td width="5%" class="text-center">
                                    <b-button variant="danger" size="sm" @click="Delete(item)" title="Excluir Manual">
                                        <b-icon icon="trash" font-scale="1" />
                                    </b-button>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr><td colspan="3" class="text-center py-3">Nenhum Manual encontrada</td></tr>
                        </template>
                    </rich-table>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import RichTable from '@/components/RichTable';
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'

export default {
    data() {
        return {
            show: true,
            table_numbers: {
                size: 1,
                total: 3,
                found: 3,
                footer: null
            },
            loadingData: false,
            filters: {
                show: 10,
                title: '',
                period: null
            },
            items: [],
        }
    },
    components: {
        RichTable
    },
    computed: {
        table_cols() {
            let cols = [{
                    id: 'title',
                    text: 'Manual',
                    class: 'cols_title'
                }, {
                    id: 'button1',
                    text: '',
                    class: 'cols_button_1'
                }, {
                    id: 'button2',
                    text: '',
                    class: 'cols_button_2'
                }
            ];
            return cols;
        },
    },
    mounted() {
        this.LoadData();
    },

    methods: {
        filterTrainings() {
            this.LoadData();
        },
        ChangeTableConfig(value) {
            this.table_config = value
            this.LoadData();
        },

        LoadData() {
            this.loadingData = true;

            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('training/getTrainings', params).then(data => {
                this.loadingData = false;
                this.items = data.items ? data.items : [];
                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : 0;
            });
        },

        Delete(training) {
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: 'Excluir Manual',
                html: `Deseja realmente excluir o manual <strong>${training.title}</strong>?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('training/deleteTraining', training).then(response => {
                        this.LoadData();
                        this.Sweealert2('success', response.success);
                    }).catch(error => {
                        console.log(error);
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: `Erro ao excluir o manual! Error: ${error}`,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

        Sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    }
}
</script>