import Vue from 'vue';
import Http from '@/api/http';
import ModuleDomain from '@/domain/module'

const state = {
    modules: [],
    module: new ModuleDomain(),
    // permissions: null,
    isUpdate: false,
    is_update: false,
    pdf: null,
    errors: {}
};

const getters = {
    // getPermissionModule: (state) => (module) => {
    //     return state.employee[`module_${module}`];
    // },
    IsUpdate: (state) => {
        return state.is_update
    },
};

const actions = {
    getPdf({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get(`module/${params.module_id}/files/${params.file_id}`, {
                responseType: 'blob'
            }).then(response => {
                commit('SET_PDF', response.data)
                resolve(response.data);
            }).catch(error => {
                reject(error)
            })
        });
    },
    loadInsertForm({ commit, dispatch }) {
        commit('SET_MODULE_ERROR', {});
        dispatch("IsUpdate", false);
        commit('SET_MODULE', new ModuleDomain);
        // Http.get('employee/permissions').then(response => {
        //     commit('SET_PERMISSIONS', response.data);
        // })
    },

    loadEditForm({ commit, dispatch }, id) {
        dispatch("IsUpdate", true);
        commit('SET_MODULE_ERROR', {});
        Http.get('module/' + id).then(response => {
            commit('SET_MODULE', response.data.module)
            // commit('SET_PERMISSIONS', response.data.permissions)
        })
    },

    IsUpdate({ commit }, is_update) {
        commit("MODULE_IS_UPDATE", is_update)
    },

    // updatePermission({ commit }, {module, new_value} ) {
    //     commit('UPDATE_PERMISSION', {module, new_value})
    // },

    getModules({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('module', {params: params}).then(response => {
                commit('SET_MODULES', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getModuleById({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.get('module/' + id).then(response => {
                commit('SET_MODULE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    createModule({ state }) {
        return Http.post('module', state.module);
    },
    
    deleteModule({commit}, module) {
        return new Promise((resolve, reject) => {
            Http.delete('module/' + module.id).then(response => {
                commit('DELETE_MODULE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateModule({commit, state}) {
        return new Promise((resolve, reject) => {
            var data = window.formDataFromObj(state.module);
            Http.post('module/' + state.module.id, data).then(response => {
                commit('UPDATE_MODULE', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // * PHONE =============================
    createPhone({ commit, state }, index) {
        let phone = state.employee.person.phones[index]
        phone['person_id'] = state.employee.person.id

        return new Promise((resolve, reject) => {
            Http.post('phone', phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },

    updatePhone({ commit, state },index) {
        let phone = state.employee.person.phones[index]
        return new Promise((resolve, reject) => {
            Http.put(`phone/${phone.id}`, phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },

    deletePhone({ commit, state },index) {
        return new Promise((resolve, reject) => {
            Http.delete(`phone/${state.employee.person.phones[index].id}`).then(response => {
                commit('SET_PHONE_DELETE', index)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },
    // * ===========================================

};

const mutations = {
    MODULE_IS_UPDATE(state, is_update) {
        state.is_update = is_update
    },

    SET_PDF(state, value) {
        state.pdf = value;
    },

    SET_TRAININGS(state, trainings) {
        state.trainings = trainings;
    },

    SET_MODULES(state, modules) {
        state.modules = modules;
    },

    SET_MODULE(state, module) {
        state.module = module;
    },
    
    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions;
    },

    UPDATE_PERMISSION(state, {module, new_value}) {
        state.module[`module_${module}`] = new_value;
    },

    CREATE_MODULE(state, module) {
        state.modules.push({module});
    },

    UPDATE_MODULE(state, module) {
        Vue.set(state.module, module);
    },

    DELETE_MODULE(state, module) {
        state.module = module
    },

    // * ERRORS
    SET_CLEAR_ERRORS(state){
        state.errors = {}
    },

    SET_MODULE_ERROR(state, value){
        state.errors = value
    },

    SET_ADD_ERROR_IN_ARRAY(state, err){
        var error_array = {}
        Object.keys(err.error).map((item) => {
            error_array[`${err.index}.${item}`] = err.error[item]
        });
        state.errors = error_array
    },
    
    //MODULE
    SET_TITLE(state, value){
        state.module.title = value
    },

    SET_TRAINING_ID(state, value){
        state.module.training_id = value
    },

    SET_FILES(state, file) {
        state.module.files.push(file);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};